import { useMemo } from 'react';

import { useMediaQuery } from 'styled-breakpoints/use-media-query';
import { useTheme } from 'styled-components';
import { ExitIntentOverlay } from 'components/ExitIntentOverlay';
import { useExitIntent } from 'hooks/useExitIntent';
import { Layout } from 'components/Layout';
import { Seo } from 'components/Seo';
import { PaginationButtons } from 'components/PaginationButtons';
import { Container } from 'components/Container';
import { BlogHeroContainer, BlogCards, BlogCard } from 'components/Blog';
import { CampaignMonitorButton } from 'components/CampaignMonitorButton';
import { getParsedBlogPost } from 'helpers/getParsedBlogPost';

import type { FC } from 'react';
import type { LocalizedPageProps } from '@sharesight/gatsby-plugin-sharesight-localization';
import type { ContentfulBlogPostRaw } from 'types/blog/blogPost';
import type { BlogCategory as BlogCategoryInterface } from 'types/blog/category';

const BlogCategory: FC<
  LocalizedPageProps & {
    pageContext: {
      entries: ContentfulBlogPostRaw[];
      prevPath?: string;
      nextPath?: string;
      categories: BlogCategoryInterface[];
    };
  }
> = ({ pageContext, path }) => {
  const { showOverlay, handleClose } = useExitIntent('blog');

  const [heroPost, posts] = useMemo(() => {
    const parsed = pageContext.entries.map(post => getParsedBlogPost(post));
    const [hero, ...rest] = parsed;

    return [hero, rest];
  }, [pageContext.entries]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Layout>
      {showOverlay && <ExitIntentOverlay handleClose={handleClose} />}
      <Seo prevPath={pageContext.prevPath} nextPath={pageContext.nextPath} />
      <BlogHeroContainer currentPage={path} categories={pageContext.categories} isMainHeader />

      <Container
        padding={15}
        bottomPadding={6}
        scaleMobilePadding
        manageLayout={false}
        skipColoration
      >
        <BlogCard entry={heroPost} isHero={!isMobile} />
      </Container>

      <Container padding={2} manageLayout={false} skipColoration>
        <h4>Latest posts</h4>
        <CampaignMonitorButton />
      </Container>

      <Container padding={2} manageLayout={false} skipColoration>
        <BlogCards entries={posts} />

        <PaginationButtons prevPath={pageContext.prevPath} nextPath={pageContext.nextPath} />
      </Container>
    </Layout>
  );
};

export default BlogCategory;
