import { Container, Section } from 'components/Container';
import { HrefButton } from 'components/Button';
import type { ContainerBackgroundProps } from 'components/Container';

export const PaginationButtons = ({
  prevPath,
  nextPath,
  containerColor = 'white',
}: {
  prevPath?: string;
  nextPath?: string;
  containerColor?: ContainerBackgroundProps['color'];
}) => (
  <Container color={containerColor} manageLayout={false}>
    <Section center>
      {!!prevPath && (
        <HrefButton appearance="secondary" href={prevPath}>
          Load previous
        </HrefButton>
      )}
      {'  '}
      {!!nextPath && (
        <HrefButton appearance="secondary" href={nextPath}>
          Load next
        </HrefButton>
      )}
    </Section>
  </Container>
);
